<template>
  <div></div>
</template>
<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
// import 'tippy.js/dist/border.css';
// import 'tippy.js/themes/light.css';
// import 'tippy.js/animations/scale-subtle.css';

export default {
  name: 'HoverToolTip',
  props: {
    selector: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: 'right',
    },
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Number,
      default: 10,
      required: false,
    },
  },
  watch: {
    disableTooltip: function (val) {
      if (val) {
        this.inst[0]?.disable();
      } else {
        this.inst[0]?.enable();
      }
    },
    label: function (val) {
      if (!val) this.inst[0]?.disable();
      else {
        this.inst[0]?.enable();
        this.inst[0]?.setContent(this.getContent());
      }
    },
    placement: function () {
      this.inst[0]?.setProps({ placement: this.placement });
    },
  },
  data() {
    return {
      inst: null,
    };
  },
  mounted() {
    this.inst = tippy(this.selector, {
      theme: 'meetric-tooltip',
      content: this.getContent(),
      allowHTML: true,
      placement: this.placement,
      interactive: true,
      interactiveBorder: 5,
      arrow: true,
      animation: 'fade',
      maxWidth: 'none',
      delay: [300, 100],
      // trigger: 'click',
      touch: false,
      offset: [0, this.offset],
      appendTo: () => document.body,
    });

    if (this.disableTooltip || !this.label) this.inst[0]?.disable();
  },

  methods: {
    getContent() {
      if (this.url) {
        if (this.placement == 'right') return this.getUrl() + this.label;
        else return this.label + this.getUrl();
      }

      return this.label;
    },
    getUrl() {
      return `
      <a href="${this.url}" target="_blank">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="inline-block text-white dark:text-base5 stroke-current"
          style="vertical-align: text-top;"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <circle cx="12" cy="12" r="9" />
          <line x1="12" y1="17" x2="12" y2="17.01" />
          <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
        </svg>
      </a>
      `;
    },
  },
};
</script>
<style>
.tippy-box[data-theme~='meetric-tooltip'] {
  @apply flex bg-base1 text-sm text-white dark:text-base5 whitespace-nowrap text-center  my-auto py-1 px-2 z-20 rounded shadow-md;
}
.tippy-box[data-theme~='meetric-tooltip'] .tippy-content {
  padding: 0;
  text-align: center;
  white-space: normal;
  max-width: 90vw;
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
  border-top-width: 16px;
  border-top-color: rgb(var(--base-01));
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-width: 16px;
  border-bottom-color: rgb(var(--base-01));
}
.tippy-box[data-placement^='left'] > .tippy-arrow::before {
  border-left-width: 16px;
  border-left-color: rgb(var(--base-01));
}
.tippy-box[data-placement^='right'] > .tippy-arrow::before {
  border-right-width: 16px;
  border-right-color: rgb(var(--base-01));
}
/* .tooltipleft {
  border-color: transparent rgb(var(--base-01)) transparent transparent;
}
.tooltipright {
  border-color: transparent transparent transparent rgb(var(--base-01));
}

.tooltipx-enter-active {
  transition: opacity 0.5s ease 0.1s, transform 0.5s ease 0.1s;
}

.tooltipx-enter {
  width: 0;
  opacity: 0;
} */
</style>
